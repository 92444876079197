import * as Sentry from '@sentry/browser';

import 'antd/dist/reset.css';
import './polyfill';
import './extension';
import './global.styl';
import './index.global.styl';
import { browserUtils } from 'tds-common-fe';
import notificationSettings from './constants/notificationSettings';
import { getLocalItem } from './utils/browser/storageUtils';
import { updateGlobalVariables } from './globalVariables';
import { MAP_VIEWER, STANDALONE_VIEWERS_LIST, APP_ROUTES } from './components/Routes/urls';
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import config from './config';
import initDayjsExtensions from './utils/date/initDayjs';

if (config.NODE_ENV === 'production') {
	Sentry.init({
		dsn: config.SENTRY_DSN,
		environment: config.APP_ENV,
		ignoreErrors: [
			'ResizeObserver loop limit exceeded',
			'ResizeObserver loop completed with undelivered notifications.',
			"Cannot read properties of null (reading 'isRooted')",
		],
		release: config.APP_VERSION,
	});
}

initDayjsExtensions();

function handleError(error: Error) {
	Sentry.captureException(error);
	location.reload();
}

if (config.APP_ENV !== 'production') {
	// Add meta[name="robots"] to index.html to prevent search engine indexing
	const meta = document.createElement('meta');
	meta.name = 'robots';
	meta.content = 'noindex nofollow';
	document.head.appendChild(meta);
}

const loadScript = ({ url, async, onload, id }: { url: string; async: boolean; onload?: () => void; id?: string }) => {
	const script = document.createElement('script');
	script.src = url;
	script.async = async;
	script.type = 'text/javascript';
	if (id) script.id = id;
	if (onload) script.onload = onload;
	document.body.appendChild(script);
};

// viewer used by backend at aws-lamda which don't need map.js / one signal.js
if (!STANDALONE_VIEWERS_LIST.includes(location.pathname)) {
	loadScript({
		url: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
		async: false,
		onload: () => {
			const sdk = window.OneSignal || null;
			if (notificationSettings && sdk) {
				sdk.push([
					'init',
					{
						appId: notificationSettings.APP_ID,
						autoRegister: false,
						notificationClickHandlerMatch: 'origin',
						notificationClickHandlerAction: 'focus',
						allowLocalhostAsSecureOrigin: true,
						safari_web_id: notificationSettings.SAFARI_ID,
						persistNotification: false,
					},
				]);
				sdk.log.setLevel('warn');
			}
		},
	});
}
if (!STANDALONE_VIEWERS_LIST.includes(location.pathname) || location.pathname === MAP_VIEWER) {
	loadScript({ url: 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js', async: false });
}

const enableHubspot = getLocalItem('enableHubspot');
// Not load huspot script for standalone viewers
if (
	![...STANDALONE_VIEWERS_LIST, ...APP_ROUTES].includes(location.pathname) &&
	config.HUPSPOT_URL &&
	enableHubspot === 'true'
) {
	loadScript({ url: config.HUPSPOT_URL, async: false, id: 'hs-script-loader' });
}

const forceRender = getLocalItem('forceRender');
const forceWeb = forceRender === 'web' || STANDALONE_VIEWERS_LIST.includes(location.pathname);
const forceMobile = forceRender === 'mobile';

if (forceMobile || (!forceWeb && browserUtils.isMobileBrowser())) {
	updateGlobalVariables({ isMobile: true });
	import('./mobile').catch(handleError);
} else {
	import('./web').catch(handleError);
}
