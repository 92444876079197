const config = {
	APP_ENV: process.env.APP_ENV,
	APP_VERSION: process.env.REACT_APP_VERSION,
	API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
	PSPDFKIT_LICENSE:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_PSPDFKIT_LICENSE
			: process.env.REACT_APP_PSPDFKIT_LICENSE_LOCAL,
	SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
	NODE_ENV: process.env.NODE_ENV,
	ONE_SIGNAL_APP_ID: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
	ONE_SIGNAL_SAFARI_ID: process.env.REACT_APP_ONE_SIGNAL_SAFARI_ID,
	EAGLE_ID_DOMAIN: process.env.REACT_APP_EAGLE_ID_DOMAIN,
	EAGLE_ID_CLIENT_ID: process.env.REACT_APP_EAGLE_ID_CLIENT_ID,
	REGIONAL_DOMAIN: process.env.REACT_APP_REGIONAL_DOMAIN,
	LOG_MISC_EVENTS: process.env.REACT_APP_LOG_MISC_EVENTS,
	SHOW_ANALYTICS_LOG: process.env.REACT_APP_SHOW_ANALYTICS_LOG,
	APP_STORE_URL: process.env.REACT_APP_APP_STORE_URL,
	IS_REPORT_GENERATION: process.env.IS_REPORT_GENERATION,
	HUPSPOT_URL: process.env.REACT_APP_HUPSPOT_URL,
	SPREADJS_KEY: process.env.REACT_APP_SPREADJS_KEY,
	WEB_URL: process.env.REACT_APP_WEB_URL,
};

export default config;
